<template>
  <v-container>
    <v-row
        justify="space-between"
        class="mx-0"
      >
        <h3>Watch Lists:</h3>
        <div>
          <v-btn icon @click.stop="openCreateWatchListDialog">
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
          <v-btn icon @click.stop="modifyWatchLists = !modifyWatchLists">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </div>
    </v-row>
    <v-row class="mx-0">
      <v-list
        width="100%"
      >
        <v-list-group
          v-for="watchlist in watchLists"
          :key="watchlist.id"
          v-model="watchlist.active"
          :prepend-icon="watchlist.action"
          no-action
        >
          <template #activator>
            <v-list-item-content>
              <v-row class="mx-0">
                  <div v-if="modifyWatchLists" class="pr-4 align-self-center">
                    <v-btn @click="openWatchListSettingDialog({...watchlist})">
                     <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-btn>
                    <v-btn @click="deleteWatchList(watchlist.id)">
                      <v-icon>mdi-alpha-x-circle-outline</v-icon>
                    </v-btn>
                  </div>
                  <div class="align-self-center py-4">
                    <h3>{{watchlist.name}}</h3>
                  </div>
              </v-row>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="ticker in watchlist.tickers"
            :key="ticker.id"
            :to="{name:'overview', params: {ticker: ticker.symbol}}"
            class="pl-3 pr-0 text-center"
          >
            <v-list-item-content>
              <v-row>
              <v-col>
                {{ ticker.symbol }}
              </v-col>
              <v-col>
                graph
              </v-col>
              <v-col>
                {{ ticker.name }}
              </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-dialog
          v-model="watchListSettingDialog.active"
          scrollable
          width="500"
        >
          <v-card>
            <v-card-title>
              Settings for: {{watchListSettingDialog.name}}
            </v-card-title>
            <v-col>
              <v-text-field
                label="Watch List Name"
                v-model="watchListSettingDialog.name"
                :rules="watchListSettingDialog.nameRules"
                hide-details="auto"
                @keypress.enter="watchListNameUpdate"
              >
              </v-text-field>
            </v-col>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="watchListSettingDialog.active = false"
              >
                Cancel
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="watchListNameUpdate"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="createWatchListDialog.active"
          scrollable
          width="500"
        >
          <v-card>
            <v-card-title>
              Create New Watch Lists
            </v-card-title>
            <v-col>
              <v-text-field
                label="Watch List Name"
                v-model="createWatchListDialog.name"
                :rules="createWatchListDialog.nameRules"
                hide-details="auto"
                @keypress.enter="watchListCreate"
              >
              </v-text-field>
            </v-col>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="createWatchListDialog.active = false"
              >
                Cancel
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="watchListCreate"
              >
                Apply
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-list>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    modifyWatchLists: false,
    watchListSettingDialog: {
      active: false,
      watchListId: '',
      name: '',
      nameRules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters'
      ]
    },
    createWatchListDialog: {
      active: false,
      name: '',
      nameRules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters'
      ]
    }
  }),
  computed: {
    watchLists () {
      const watch = this.$store.getters.user.watchLists?.map(watchlist => { return { ...watchlist, active: false } })
      return ((watch?.length ?? 0) > 0) ? [...watch] : []
    }
  },
  methods: {
    hasTickers (watchlist) {
      return watchlist?.length !== 0 ?? 0
    },
    deleteWatchList (id) {
      this.$store.dispatch('deleteWatchList', id)
    },
    watchListCreate () {
      const name = this.createWatchListDialog.name === '' ? 'New Watch List' : this.createWatchListDialog.name
      this.$store.dispatch('watchListCreate', name)
      this.createWatchListDialog.active = false
    },
    openWatchListSettingDialog ({ id, name }) {
      this.watchListSettingDialog.watchListId = id
      this.watchListSettingDialog.name = name
      this.watchListSettingDialog.active = true
    },
    openCreateWatchListDialog () {
      this.createWatchListDialog.name = ''
      this.createWatchListDialog.active = true
    },
    watchListNameUpdate () {
      const name = this.watchListSettingDialog.name === '' ? 'New Watch List' : this.watchListSettingDialog.name
      this.$store.dispatch('changeWatchListName', { watchListId: this.watchListSettingDialog.watchListId, watchListName: name })
      this.watchListSettingDialog.active = false
    }
  }
}
</script>
<style scoped>
.w-80{
  width:80%
}
</style>
